<template>
  <v-dialog
    :content-class="innerUserId ? 'UserDialog UserDialog--edit' : 'UserDialog UserDialog--create'"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="saving"
    max-width="466px"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <form
      v-if="!innerUserId || user"
      class="UserDialog__form"
      @submit.prevent="validateAndSubmit()"
    >
      <h1
        v-if="!innerUserId"
        class="UserDialog__title display-3"
        v-text="$t('user.NewUser')"
      />

      <div
        v-else-if="user"
        class="d-flex align-center text-truncate mb-3"
      >
        <UserAvatar
          :user="{ ...user, ...form }"
          size="xl"
        />
        <div class="text-truncate ml-4">
          <div class="display-2 text-truncate">
            {{ form.firstName }} {{ form.lastName }}
          </div>
          <div
            class="text--secondary text-truncate"
            v-text="[
              form.userLogin || user.userLogin,
              form.userEmail || user.userEmail,
            ].filter(x => !!x).join(' ')"
          />
        </div>
      </div>

      <div class="UserDialog__scrollable">
        <AppTextField
          v-if="!passwordChangeRequired"
          key="first-name"
          v-model="form.firstName"
          :label="$t('user.FirstName')"
          filled
          type="text"
          name="first-name"
          maxlength="128"
          autocomplete="given-name"
          :autofocus="!userId"
          :error-messages="getErrors('firstName')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        />

        <AppTextField
          v-if="!passwordChangeRequired"
          key="last-name"
          v-model="form.lastName"
          :label="$t('user.LastName')"
          filled
          type="text"
          name="last-name"
          maxlength="128"
          autocomplete="family-name"
          :error-messages="getErrors('lastName')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        />

        <AppTextField
          v-if="!passwordChangeRequired"
          key="login"
          v-model="form.userLogin"
          :label="$t('user.Login')"
          filled
          type="text"
          name="login"
          required
          autocomplete="nickname"
          :readonly="!!innerUserId || !!(user && user.userType === USER_TYPE.LDAP)"
          :error-messages="getErrors('userLogin')"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
        />

        <AppTextField
          v-if="!passwordChangeRequired"
          key="email"
          v-model="form.userEmail"
          :label="$t('user.Email')"
          filled
          type="email"
          name="email"
          autocomplete="email"
          :error-messages="getErrors('userEmail')"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
        />

        <AppTextField
          v-if="!user || user.userType === USER_TYPE.LOCAL"
          key="password"
          v-model="form.userPassword"
          :label="$t('user.Password')"
          filled
          :type="previewPassword ? 'text': 'password'"
          name="password"
          autocomplete="new-password"
          maxlength="128"
          :required="!userId"
          :error-messages="getErrors('userPassword')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        >
          <template #append>
            <v-btn
              icon
              @click="previewPassword = !previewPassword"
            >
              <v-icon
                v-text="previewPassword ? '$eye-close' : '$eye'"
              />
            </v-btn>
          </template>
        </AppTextField>

        <AppTextField
          v-if="!user || user.userType === USER_TYPE.LOCAL"
          key="password-confirm"
          v-model="form.userPasswordConfirm"
          :label="$t('user.PasswordConfirm')"
          filled
          :type="previewPasswordConfirm ? 'text': 'password'"
          name="passwordConfirm"
          autocomplete="new-password"
          maxlength="128"
          :required="!userId"
          :error-messages="getErrors('userPasswordConfirm')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        >
          <template #append>
            <v-btn
              icon
              @click="previewPasswordConfirm = !previewPasswordConfirm"
            >
              <v-icon
                v-text="previewPasswordConfirm ? '$eye-close' : '$eye'"
              />
            </v-btn>
          </template>
        </AppTextField>

        <AppSelect
          v-if="!passwordChangeRequired"
          key="role"
          v-model="form.role"
          :label="$t('user.Role')"
          filled
          type="text"
          name="role"
          required
          autocomplete="off"
          :items="userRoles"
          item-value="name"
          item-text="displayName"
          :readonly="!!(user && user.userType === USER_TYPE.LDAP)"
          :error-messages="[]"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
        >
          <template #item="{ item: role }">
            <v-icon
              class="mr-2"
              v-text="role.icon"
            />
            {{ $t(`user.${role.displayName}`) }}
          </template>
          <template #selection="{ item: role }">
            <v-icon
              class="mr-2"
              v-text="role.icon"
            />
            {{ $t(`user.${role.displayName}`) }}
          </template>
        </AppSelect>

        <v-checkbox
          v-if="!!innerUserId && currentUser && currentUser.isAdmin && !passwordChangeRequired"
          :input-value="form.state === USER_STATE.BLOCKED.value"
          :disabled="saving"
          name="is-blocked"
          hide-details
          :label="$t('user.Block')"
          class="my-1"
          @change="onBlockedChange($event)"
        />

        <v-checkbox
          v-if="!!innerUserId && !passwordChangeRequired"
          v-model="form.mfa"
          :disabled="!user ||
            saving ||
            (currentUser && currentUser.isAdmin && currentUser.id !== innerUserId && !form.mfa)"
          name="mfa-on"
          hide-details
          :label="$t('user.SignInWithCodeM')"
          class="mb-8 mt-1"
        />

        <AppSelect
          v-if="!!innerUserId && !passwordChangeRequired && profile && appLanguages.length > 1"
          key="language"
          v-model="form.language"
          :label="$t('user.Language')"
          type="text"
          name="language"
          required
          autocomplete="off"
          :items="appLanguages"
          item-value="name"
          item-text="displayName"
          :error-messages="[]"
          :disabled="saving || !currentUser "
        >
          <template #item="{ item: language }">
            {{ language.displayName }}
          </template>
          <template #selection="{ item: language }">
            {{ language.displayName }}
          </template>
        </AppSelect>

        <div v-if="passwordChangeRequired">
          <v-icon
            :size="24"
            color="#FFC480"
            v-text="'mdi-alert-outline'"
          />
          <span class="ml-2 mr-2">{{ $t('user.UpdatePassword') }}</span>
        </div>
      </div>

      <section class="UserDialog__actions pt-10">
        <v-btn
          v-if="!innerUserId"
          :disabled="saving"
          :loading="saving"
          outlined
          color="primary"
          :to="createBotRoute"
          replace
          exact
        >
          {{ $t('user.CreateBotUser') }}
        </v-btn>
        <!-- form actions -->
        <!-- disabling ability to delete user for now -->
        <!-- jira bugfix 1788-->
        <!--          <v-btn-->
        <!--            disabled-->
        <!--            outlined-->
        <!--            color="error"-->
        <!--            @click.prevent.stop="deleteUser"-->
        <!--          >-->
        <!--            Delete-->
        <!--          </v-btn>-->
        <!-- jira bugfix 1788-->
        <v-spacer />
        <v-btn
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
          :loading="saving"
          type="submit"
          depressed
          color="primary"
          @click="$v.$touch()"
        >
          {{ innerUserId ? $t('user.Save') : $t('user.Create') }}
        </v-btn>
        <v-btn
          :disabled="saving"
          outlined
          color="primary"
          class="ml-2"
          @click.prevent.stop="$emit('input', false)"
        >
          {{ $t('user.Cancel') }}
        </v-btn>
      </section>
    </form>

    <MfaSetupDialog
      :value="mfaDialog"
      v-bind="mfaDialogState"
      @input="$event ? (mfaDialog = true) : onMfaDialogRejection()"
      @success="onMfaDialogSuccess"
    />
  </v-dialog>
</template>

<script>
import * as R from 'ramda'
import { required, maxLength, minLength, email, sameAs } from 'vuelidate/lib/validators'
import i18n from '../i18n'

import { USER_STATE, USER_TYPE, AUTH } from '../constants'
import { reportError, replaceRoute } from '../helpers'

import UserRole from '../store/orm/userRole'

import MfaSetupDialog from './MfaSetupDialog'
import UserAvatar from './UserAvatar'

const validationErrors = {
  'firstName-maxLength': i18n.t('user.validationErrors.FirstNameMaxLength'),
  'lastName-maxLength': i18n.t('user.validationErrors.LastNameMaxLength'),
  'userLogin-required': i18n.t('user.validationErrors.UserLoginRequired'),
  'userEmail-email': i18n.t('user.validationErrors.UserEmail'),
  'userPassword-required': i18n.t('user.validationErrors.UserPasswordRequired'),
  'userPassword-minLength': i18n.t('user.validationErrors.UserPasswordMinLength'),
  'userPassword-maxLength': i18n.t('user.validationErrors.UserPasswordMaxLength'),
  'userPasswordConfirm-sameAsPassword': i18n.t('user.validationErrors.UserPasswordConfirm'),
}

const formValidation = (isNew) => {
  const userPassword = { minLength: minLength(6), maxLength: maxLength(128) }

  if (isNew) userPassword.required = required

  return {
    firstName: { maxLength: maxLength(64) },
    lastName: { maxLength: maxLength(64) },
    userLogin: { required },
    userEmail: { email },
    userPassword,
    userPasswordConfirm: { sameAsPassword: sameAs('userPassword') },
  }
}

export default {
  name: 'UserDialog',

  components: {
    MfaSetupDialog,
    UserAvatar,
  },

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true }, // isOpen?
    userId: { type: String, default: null },
    passwordChangeRequired: { type: Boolean, default: false },
    profile: { type: Boolean, default: false },
  },

  data() {
    return {
      USER_STATE,
      USER_TYPE,
      AUTH,

      // preserves user id during dialog fade out animation
      innerUserId: this.userId,
      form: null,
      previewPassword: false,
      previewPasswordConfirm: false,

      mfaDialog: false,
      mfaDialogState: {
        userId: null,
        otpauthUrl: '',
        base32: '',
      },

      saving: false,
    }
  },

  validations() {
    return {
      form: formValidation(!this.userId),
    }
  },

  computed: {
    user() {
      const { $store, innerUserId: userId } = this
      return userId ? $store.getters['user/get'](userId) : null
    },

    currentUser() {
      return this.$store.getters['user/current'] || null
    },

    userRoles() {
      return UserRole.getOrderedQuery().all()
    },

    firstError() {
      const fields = Object.keys(formValidation(!this.userId))
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },

    createBotRoute() {
      const { $route } = this

      const query = $route.name === 'Users'
        ? R.omit(['userId', 'botId'], $route.query)
        : {}
      query.action = 'create-bot'

      return { name: 'Users', query }
    },

    authType() { return this.$store.state.service.serverInfo?.authenticationMethod },

    appLanguages() {
      return this.$store.state.ext.environment?.product?.languages || []
    },
  },

  watch: {
    value: {
      handler(isOpen) {
        this.$store.commit('defaultLayout/setPushAjaxLoaderTop', isOpen)
        if (isOpen) {
          UserRole.dispatch('$get')
          this.initForm()
        } else {
          this.$router.replace({
            ...this.$route,
            query: { ...R.omit(['changePassword'], this.$route.query) },
          }).catch(() => {})
        }
      },
      immediate: true,
    },

    userId(userId) {
      if (this.value) this.innerUserId = userId
      else {
        setTimeout(() => {
          this.innerUserId = this.userId
        }, 300)
      }
    },

    user: {
      handler: 'initForm',
      immediate: true,
    },

    innerUserId: {
      handler: 'fetchUserDetails',
      immediate: true,
    },
  },

  async created() {
    this.$mfaPromiseResolve = null
    this.$mfaPromiseReject = null
    await this.$store.dispatch('service/getServerInfo')
  },

  methods: {
    async fetchUserDetails(userId) {
      if (!userId) return
      await this.$store.dispatch('user/getDetails', { userId })
    },

    initForm() {
      const { user } = this
      this.form = user
        ? R.pipe(
          R.clone,
          R.over(R.lensProp('firstName'), R.defaultTo('')),
          R.over(R.lensProp('lastName'), R.defaultTo('')),
          R.over(R.lensProp('userEmail'), R.defaultTo('')),
          R.over(R.lensProp('role'), R.defaultTo(UserRole.DEFAULT_ROLE)),
          R.over(R.lensProp('language'), R.defaultTo(this.$store.state.ext.environment?.product?.languages.find(lang => lang.name === this.$i18n.locale))),
          R.ifElse(
            R.propEq('mfaConfirmed', true),
            R.assoc('mfa', true),
            R.assoc('mfa', false),
          ),
          R.assoc('userPassword', ''),
          R.assoc('userPasswordConfirm', ''),
          R.omit(['id', 'mfaConfirmed', 'isAdmin', 'permissions']),
        )(user)
        : this.getDefaultForm()
      this.previewPassword = false
      this.previewPasswordConfirm = false
      this.$v.$reset()
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('user.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit() {
      await this.$nextTick()

      const { $store, form, innerUserId: userId, currentUser } = this

      this.$v.$touch() // highlight errors
      if (this.$v.$error) {
        return this.$store.commit('$snackbar/setMessage', {
          message: this.firstError || this.$t('user.fixErrorsM'),
        })
      }
      let saved = null
      if (currentUser?.isAdmin) {
        saved = userId
          ? $store.dispatch('user/update', {
            user: {
              id: userId,
              ...R.pipe(
                R.when(() => currentUser.id === userId, R.omit(['state'])),
                R.omit(['userType', 'mfa', 'userPasswordConfirm', 'passwordChangeRequired', 'language']),
                R.when(form => !form.userPassword.trim(), R.omit(['userPassword'])),
                R.when(form => !form.userEmail.trim(), R.assoc('userEmail', null)),
              )(form),
            },
          })
          : $store.dispatch('user/register', {
            form: R.pipe(
              R.omit(['isAdmin', 'state', 'userType', 'mfa', 'userPasswordConfirm', 'passwordChangeRequired', 'language']),
              R.when(form => !form.userEmail.trim(), R.assoc('userEmail', null)),
            )(form),
            signIn: false,
          })
            .then((userId) => {
              if (form.isAdmin || form.state !== USER_STATE.ACTIVE.value) {
                return $store.dispatch('user/update', {
                  user: {
                    id: userId,
                    ...R.pick(['isAdmin', 'state'], form),
                  },
                })
              }
              return userId
            })
      } else {
        saved = $store.dispatch('user/update', {
          user: {
            id: userId,
            ...R.pipe(
              R.when(() => currentUser.id === userId, R.omit(['state'])),
              R.omit(['userType', 'mfa', 'isAdmin', 'userEmail', 'userPasswordConfirm', 'passwordChangeRequired', 'role', 'language']),
              R.when(form => !form.userPassword.trim(), R.omit(['userPassword'])),
            )(form),
          },
        }).then((userId) => {
          if (form.isAdmin || form.state !== USER_STATE.ACTIVE.value) {
            return $store.dispatch('user/update', {
              user: {
                id: userId,
                ...R.pick(['isAdmin', 'state'], form),
              },
            })
          }
          return userId
        })
      }

      this.saving = true
      try {
        const createdOrUpdatedUserId = await saved
        await this.processMfaSetup(createdOrUpdatedUserId)
        await this.onSuccess()
        $store.dispatch('ext/getLanguage', { locale: form.language })
      } catch (e) {
        reportError(e).catch(() => { /* pass  */ })
      } finally {
        this.saving = false
      }
    },

    async processMfaSetup(userId) {
      const { $store, innerUserId, user, form } = this

      // We have to set up MFA when either of two is true:
      // 1. New user and MFA checkbox has been ticked
      // Do we want to give admin possibility to create users with MFA-tokens?
      // const shouldEnableForNewUser = !innerUserId && form.mfa
      // 2. Existing user without confirmed MFA setup && MFA checkbox has been ticked
      const shouldEnableForExistingUser =
        !!innerUserId &&
        !user?.mfaConfirmed &&
        form.mfa

      const shouldDisable =
        !!innerUserId &&
        user.mfaConfirmed &&
        !form.mfa

      if (shouldEnableForExistingUser /* || shouldEnableForNewUser */) {
        const { otpauthUrl, base32 } = await $store.dispatch('user/enableMfa', { userId })

        await new Promise((resolve, reject) => {
          this.mfaDialogState = { userId, otpauthUrl, base32 }
          this.mfaDialog = true
          this.$mfaPromiseResolve = resolve
          this.$mfaPromiseReject = reject
        })
      } else if (shouldDisable) {
        await $store.dispatch('user/disableMfa', { userId })
      }
    },

    onMfaDialogRejection() {
      this.mfaDialog = false
      this.$mfaPromiseReject(new Error(this.$t('user.MfaConfigurationCancelled')))
      // this.$store.commit('$snackbar/setMessage', { message: 'MFA configuration cancelled' })
    },

    onMfaDialogSuccess() {
      this.$mfaPromiseResolve(this.mfaDialogState)
      this.mfaDialog = false
    },

    async onSuccess() {
      const { $store } = this
      await $store.dispatch('user/getList')
      this.$emit('input', false)
    },

    // async deleteUser() {
    //   const { $store, user: { id: userId } } = this
    //
    //   this.$emit('input', false)
    //
    //   const agreed = await $store.dispatch('confirm/openDialog', {
    //     title: this.$t('user.DeleteUserQ'),
    //     subtitle: this.$t('user.UserWillBeDeletedM'),
    //     consentLabel: this.$t('user.Delete'),
    //   })
    //
    //   if (agreed) {
    //     this.saving = true
    //     try {
    //       await $store.dispatch('user/delete', { userId })
    //       await this.onSuccess()
    //     } finally {
    //       this.saving = false
    //     }
    //   } else {
    //     this.restorePreviousDialog(userId)
    //   }
    // },

    restorePreviousDialog(userId) {
      const { $router, $route } = this

      const query = $route.name === 'Users' ? { ...$route.query } : {}
      query.action = 'edit-user'
      query.userId = userId

      replaceRoute($router, { name: 'Users', query })
    },

    getDefaultForm() {
      return {
        firstName: '',
        lastName: '',
        userLogin: '',
        userEmail: '',
        userPassword: '',
        userPasswordConfirm: '',
        role: UserRole.DEFAULT_ROLE,
        language: {},
        state: USER_STATE.ACTIVE.value,
        mfa: false,
      }
    },

    onBlockedChange(event) {
      const { form, user } = this
      if (event) {
        form.state = USER_STATE.BLOCKED.value
      } else if (user.state !== USER_STATE.BLOCKED.value) {
        // revert to PENDING/ACTIVE
        form.state = user.state
      } else {
        form.state = USER_STATE.ACTIVE.value
      }
    },

  },
}
</script>

<style lang="sass">
.UserDialog
  overflow: hidden
  background: white

  &__form
    padding: 48px 48px 0

  &__title
    margin-bottom: 12px

  &__scrollable
    margin: 0 -48px
    padding: 0 48px
    max-height: calc(90vh - 116px - 64px - 12px - 48px)
    overflow: hidden auto

  &__actions
    display: flex
    padding: 32px 32px 32px 48px
    margin: 0 -48px
</style>
